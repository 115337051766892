import { FC } from 'react';

import { DefaultModal } from './default-slice/DefaultModal';
import { DefaultModalVariation, ModalVariations } from './types';

export interface ModalSliceProps {
  slice: ModalVariations;
}

export const ModalSlice: FC<ModalSliceProps> = (props) => {
  const { slice } = props;

  return <DefaultModal slice={slice as DefaultModalVariation} />;
};
